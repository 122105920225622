<script setup lang="ts">
/**
 * UiLoading
 *
 * Wrapper component to formalise loading states and simplify code
 *
 * @see https://github.com/forged-com/forgd/pull/1812
 */
const props = withDefaults(defineProps<{
  type?: 'spinner' | 'bar' | 'lottie' | 'skeleton'
  loading?: boolean
  percent?: number
  size?: 'xs' | 'sm' | 'md' | 'lg'
  delay?: number
  dim?: boolean
  once?: boolean
}>(), {
  loading: false,
  percent: 0,
  type: 'spinner',
  delay: 0,
  dim: false,
  once: false,
})

const slots = useSlots()

const circleSize = computed(() => {
  const sizes = {
    xs: [16, 2],
    sm: [24, 3],
    md: [36, 4],
    lg: [72, 5],
  }
  const [size, stroke] = props.size
    ? sizes[props.size]
    : typeof slots.default === 'function' && slots.default()
      ? sizes.md
      : sizes.xs
  return {
    size,
    stroke,
  }
})

const lottieSize = computed(() => {
  const sizes = {
    xs: 'size-[30px]',
    sm: 'size-[50px]',
    md: 'size-[100px]',
    lg: 'size-[200px]',
  }
  return sizes[props.size] || sizes.md
})

// maintain separate model to track once and delay
const isLoading = ref(props.delay ? false : props.loading)

const hasLoaded = ref(false)

let timeoutId: number

watch(() => props.loading, onLoadingChange)

function onLoadingChange(value: boolean) {
  if (value) {
    if (props.delay) {
      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        setLoading(true)
      }, props.delay)
    }
    else {
      setLoading(true)
    }
  }
  else {
    setLoading(false)
  }
}

function setLoading(value: boolean) {
  if (props.once) {
    if (hasLoaded.value) {
      return
    }
    isLoading.value = false
  }
  else {
    isLoading.value = value
    if (!value) {
      hasLoaded.value = true
    }
  }
  if (!value) {
    window.clearTimeout(timeoutId)
  }
}

onMounted(() => {
  if (props.delay) {
    onLoadingChange(true)
  }
})
</script>

<template>
  <span
    :data-ui="isLoading ? 'UiLoading' : undefined"
    :data-loading="isLoading ? 1 : 0"
    class="uiLoading"
    :class="$slots.default ? 'relative' : 'inline-block w-auto'"
  >
    <!-- slot content -->
    <span
      v-if="$slots.default"
      class="uiLoading__content"
      :class="{
        'dim': isLoading && dim,
        'pointer-events-none': isLoading,
        'opacity-100': !isLoading,
      }"
    >
      <slot />
    </span>

    <!-- loading icon -->
    <Transition name="loader">
      <span
        v-show="$slots.default ? isLoading : true"
        class="uiLoading__loader"
        :class="
          $slots.default
            ? 'absolute inset-0 flex justify-center items-center h-full w-full'
            : [
              'inline-block translate-y-0.5 transition-opacity duration-500',
              isLoading ? 'opacity-100' : 'opacity-0',
            ]
        "
      >
        <UiProgressCircle
          v-if="type === 'spinner'"
          percent="25"
          :size="circleSize.size"
          :stroke-size="circleSize.stroke"
          class="animate-spin"
        />
        <UiProgressBar
          v-else-if="type === 'bar'"
          :percent="percent"
          class="block w-1/2"
        />
        <USkeleton
          v-else-if="type === 'skeleton'"
          class="h-7 w-[150px] mx-auto bg-forgd-gray-350"
          :ui="{ rounded: 'rounded-full' }"
        />
        <span v-else :class="lottieSize">
          <UiLottie name="loading" :size="size" />
        </span>
      </span>
    </Transition>
  </span>
</template>

<style lang="scss" scoped>
$fast: opacity 0.15s ease-in-out, filter 0.15s ease-in-out;
$slow: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;

.uiLoading {

  &__content {
    opacity: 0;
    transition: $slow;
  }

  &[data-loading="0"] &__content {
    opacity: 1;
    transition: $slow;
  }

  &[data-loading="1"] &__content {
    opacity: 0;
    transition: $fast;
  }

  &[data-loading="1"] &__content.dim {
    opacity: 0.1;
    //filter: blur(2px);
  }
}

.loader-enter-active {
  transition: $slow;
  transition-delay: .15s;
}

.loader-leave-active {
  transition: $slow;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0
}

.loader-enter-to,
.loader-leave-from {
  opacity: 1
}
</style>
