<script lang="ts" setup>
import { computed } from 'vue'

/**
 * UiProgressCircle
 *
 * Shows a circular progres
 */
interface Props {
  percent: number | string
  size?: number | string
  strokeSize?: number | string
  lighter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  percent: 0,
  size: 36,
  strokeSize: 4,
})

const style = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
}))

const svg = computed(() => ({
  'view-box': `0 0 ${props.size} ${props.size}`,
  'stroke-width': props.strokeSize,
  'fill': 'none',
}))

const circle = computed(() => {
  const r = Number(props.size) / 2
  return {
    cx: r,
    cy: r,
    r: Math.max(1, r - Number(props.strokeSize) / 2),
  }
})

const offset = computed(() => clamp(100 - Number(props.percent), 0, 100))

const bg = computed(() => {
  return props.lighter
    ? 'stroke-forgd-bgd-400'
    : 'stroke-forgd-bgd-600'
})
</script>

<template>
  <div data-ui="UiProgressCircle" :style="style" class="relative shrink-0">
    <svg
      v-bind="svg"
      :class="bg"
      class="absolute inset-0 w-full h-full"
    >
      <circle v-bind="circle" />
    </svg>
    <svg
      v-bind="svg"
      :stroke-dashoffset="offset"
      :stroke-dasharray="100"
      stroke-linecap="butt"
      class="absolute inset-0 w-full h-full -rotate-90 stroke-forgd-primary-300"
    >
      <circle v-bind="circle" pathLength="100" />
    </svg>
  </div>
</template>
