<script lang="ts">
export type LottieAnimation =
  | 'chart'
  | 'contact'
  | 'contacts'
  | 'cube'
  | 'fetching-token'
  | 'loading'
  | 'onhold'
  | 'processing'
</script>

<script setup lang="ts">
// import { Vue3Lottie } from 'vue3-lottie'

/**
 * UiLottie
 *
 * Simplifies lottie usage with client-only, animation name and size props
 *
 * @see https://github.com/forged-com/forgd/pull/1812
 */
const props = defineProps<{
  name: LottieAnimation | string
  size: keyof typeof sizes
}>()

const sizes = {
  sm: 'inline-block w-28 h-28', // 112px
  md: 'inline-block w-52 h-52', // 208px
  lg: 'w-full h-full', // 100%
}

const classes = computed(() => {
  return props.size ? sizes[props.size] : ''
})
</script>

<template>
  <ClientOnly>
    <div data-ui="UiLottie" :class="classes">
      <Vue3Lottie :animation-link="name.endsWith('.json') ? name : `/lottie/${name}.json`" />
    </div>
  </ClientOnly>
</template>
